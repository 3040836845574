import "./src/styles/_global.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import { Provider } from "react-redux";
import { store } from "./src/store/store";
import React from "react";
import { TABLET_WIDTH } from "./src/helpers/ScreenSizes";

import Intercom from '@intercom/messenger-js-sdk';

Intercom({
  app_id: 'f49pmfon'
});

export const onRouteUpdate = () => {
  Aos.init({
    disable: () => typeof window !== "undefined" && window.innerWidth <= TABLET_WIDTH
  });
};

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>{element}</Provider>
);
