import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISetLocationPayload {
  countryCodeLang: string;
}

interface ILocationState {
  eventCaddyFee: number;
  stripePercentageFee: number;
  stripeFixedFee: number;
  proPrice: number;
  elitePrice: number;
  currency: string;
  currencySymbol: string;
  currencyPositionLeft: boolean;
  countryCodeLang: string;
}

const unitedStatesLocation: ILocationState = {
  eventCaddyFee: 4.00,
  stripePercentageFee: 2.9,
  stripeFixedFee: 0.3,
  proPrice: 299,
  elitePrice: 999,
  currency: "USD",
  currencySymbol: "$",
  currencyPositionLeft: true,
  countryCodeLang: "US"
}

const locationSlice = createSlice({
  name: "location",
  initialState: unitedStatesLocation,
  reducers: {
    setLocation: (state, action: PayloadAction<ISetLocationPayload>) => {
      let updatedState: ILocationState | null = null;
      
      switch (action.payload.countryCodeLang) {
        case "CA":
          updatedState = {
            eventCaddyFee: 4.00,
            stripePercentageFee: 2.9,
            stripeFixedFee: 0.3,
            proPrice: 399,
            elitePrice: 1299,
            currency: "CAD",
            currencySymbol: "$",
            currencyPositionLeft: true,
            countryCodeLang: "CA"
          };

          break;
        case "GB":
          updatedState = {
            eventCaddyFee: 2.50,
            stripePercentageFee: 2.4,
            stripeFixedFee: 0.20,
            proPrice: 239,
            elitePrice: 799,
            currency: "GBP",
            currencySymbol: "£",
            currencyPositionLeft: true,
            countryCodeLang: "GB",
          };

          break;
        case "IE":
          updatedState = {
            eventCaddyFee: 3.00,
            stripePercentageFee: 1.4,
            stripeFixedFee: 0.25,
            proPrice: 279,
            elitePrice: 929,
            currency: "EUR",
            currencySymbol: "€",
            currencyPositionLeft: false,
            countryCodeLang: "IE",
          };

          break;
        case "AU":
          updatedState = {
            eventCaddyFee: 4.50,
            stripePercentageFee: 1.75,
            stripeFixedFee: 0.25,
            proPrice: 469,
            elitePrice: 1549,
            currency: "AUD",
            currencySymbol: "$",
            currencyPositionLeft: true,
            countryCodeLang: "AU",
          };

          break;
        case "NZ":
          updatedState = {
            eventCaddyFee: 5.00,
            stripePercentageFee: 1.75,
            stripeFixedFee: 0.25,
            proPrice: 499,
            elitePrice: 1699,
            currency: "NZD",
            currencySymbol: "$",
            currencyPositionLeft: true,
            countryCodeLang: "NZ",
          };

          break;
        case "US":
        default:
          updatedState = { ...unitedStatesLocation };
      }

      if (updatedState) {
        Object.assign(state, updatedState);
      }
    }
  },
});

export const { setLocation } = locationSlice.actions;
export default locationSlice.reducer;